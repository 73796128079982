require('normalize.css/normalize.css');
require('./assets/css/style.styl');
require('./assets/js/custom.js');
// require('./assets/js/lazyload.js');
// require('./assets/js/siema.js');

function requireAll(r) {
    r.keys().forEach(r);
}
requireAll(require.context('./assets/svg', true, /\.svg$/));
requireAll(require.context('./assets/img', true, /\.(png|jpe?g|gif)$/i));


// Init
document.addEventListener("DOMContentLoaded", () => {
    console.log('start project!');
    // require('./assets/svg/sprite.svg');
});
