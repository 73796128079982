let observer = new IntersectionObserver(
    (entries, observer) => { 
        entries.forEach(entry => {
            
            if (entry.isIntersecting) { 
                entry.target.src = entry.target.dataset.src;
                observer.unobserve(entry.target);
            }
            
        }, 
        {rootMargin: "0px 0px -500px 0px"});
});
document.querySelectorAll('img').forEach(img => { observer.observe(img) });

// $('.float-nav').click(function() {
//   $('.main-nav, .menu-btn').toggleClass('active');
// });

// var btnNav = document.querySelector('.float-nav');

// btnNav.onclick = function(){
//     document.querySelector('.main-nav').classList.toggle('active');
//     document.querySelector('.menu-btn').classList.toggle('active');
    
//     document.querySelector('.main-nav > ul').onclick = function(){
//         document.querySelector('.main-nav').classList.toggle('active');
//         document.querySelector('.menu-btn').classList.toggle('active'); 
//     }
// }; 